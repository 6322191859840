import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from 'axios'
import { IssueTypes } from '@/globalVariables'
import { IndexQuestion, NewQuestion, Question } from '@/types/service/question'
import router from '@/router'
import { notification } from 'ant-design-vue'

export const questionStore = defineStore('question', () => {
  const loading = ref(false)
  const questions = ref<IndexQuestion[]>([])
  const question = ref<Question|null>(null)

  function getQuestions () : void {
    loading.value = true
    axios.get(process.env.VUE_APP_API_URL + '/issues').then((r) => {
      questions.value = r.data.data.filter((question:IndexQuestion) => {
        return question.type === IssueTypes.QUESTION
      })
    }).catch(() => {
      notification.error({
        message: 'Fout tijdens ophalen van uw vragen!',
        description: 'Er is helaas iets mis gegaan. Probeer het later nog een keer.',
        duration: 0
      })
    }).finally(() => {
      loading.value = false
    })
  }

  function getQuestion (id:string|string[]) : void {
    loading.value = true
    axios.get(process.env.VUE_APP_API_URL + '/questions/' + id).then((r) => {
      question.value = r.data
    }).catch(() => {
      router.push({ name: 'Questions' })
      notification.error({
        message: 'Fout tijdens ophalen van vraag!',
        description: 'Er is helaas iets mis gegaan. Probeer het later nog een keer.',
        duration: 0
      })
    }).finally(() => {
      loading.value = false
    })
  }

  function storeQuestion (question:NewQuestion) : void {
    loading.value = true
    axios.post(process.env.VUE_APP_API_URL + '/issues', question).then(() => {
      router.push({ name: 'Questions' })
      notification.success({
        message: 'Vraag aangemaakt.',
        description: 'Uw vraag is succesvol aangemaakt. Wij komen zo snel mogelijk met een reactie.',
        duration: 5
      })
    }).catch(() => {
      notification.error({
        message: 'Vraag NIET aangemaakt!',
        description: 'Er is helaas iets mis gegaan. Probeer het later nog een keer.',
        duration: 0
      })
    }).finally(() => {
      loading.value = false
    })
  }

  return { loading, questions, question, getQuestions, getQuestion, storeQuestion }
})
